import React, { Suspense, lazy } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { COOKIE_TOKEN_NAME } from './utils/constant'
import Cookies from 'js-cookie'

import { Spinner } from 'react-bootstrap'

// Component auth
// const Home = lazy(() => import('./views/home'))
const Chat = lazy(() => import('./views/chat'))
const Order = lazy(() => import('./views/order'))
// const Profile = lazy(() => import('./views/profile'))
const Settings = lazy(() => import('./views/settings'))
const VerifyAccount = lazy(() => import('./views/verifyAccount'))
const SearchOrder = lazy(() => import('./views/searchOrder'))
const OrderDetail = lazy(() => import('./views/orderDetail'))
const InternationalCall = lazy(() => import('./views/internationalCall'))

// Component no auth
const Login = lazy(() => import('./views/login'))
const ResetPassword = lazy(() => import('./views/login/ResetPassword'))
const ForgotPassword = lazy(() => import('./views/login/ForgotPassword'))
const Register = lazy(() => import('./views/register'))

// Component free / other
const TransferMoney = lazy(() => import('./views/transferMoney'))

// Auth routes
const authRoutes = [
  // { path: '/home', component: Home, exact: true },
  { path: '/chat/:roomId', component: Chat },
  { path: '/chat', component: Chat },
  // { path: '/profile', component: Profile },
  { path: '/settings', component: Settings },
  // { path: '/home', component: Home, exact: true },
  { path: '/order', component: Order, exact: true },
  { path: '/verifyAccount', component: VerifyAccount, exact: true },
  { path: '/order/orderDetail/:id', component: OrderDetail, exact: true }
]

// No auth routes
export const noAuthRoutes = [
  { path: '/login', component: Login },
  { path: '/register', component: Register },
  { path: '/resetPassword', component: ResetPassword },
  { path: '/forgotPassword', component: ForgotPassword }
]

// Free / Other routes
export const freeRoutes = [
  { path: '/searchOrder', component: SearchOrder },
  { path: '/transfer-money', component: TransferMoney },
  { path: '/internationalCall', component: InternationalCall }
]

// handle auth and authorization
const AppRoute = ({ component: Component, isAuthProtected, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        return <Component {...props} />
      }}
    />
  )
}

const AppRoutes = () => {
  const isAuth = Cookies.get(COOKIE_TOKEN_NAME)

  return (
    <Suspense fallback={<Spinner />}>
      <Switch>
        {isAuth &&
          authRoutes.map((route, idx) => (
            <AppRoute
              path={route.path}
              component={route.component}
              key={idx}
              isAuthProtected={true}
              exact={route.exact}
            />
          ))}

        {!isAuth &&
          noAuthRoutes.map((route, idx) => (
            <AppRoute path={route.path} component={route.component} key={idx} isAuthProtected={false} />
          ))}

        {freeRoutes.map((route, idx) => (
          <AppRoute path={route.path} component={route.component} key={idx} isAuthProtected={false} />
        ))}

        <Redirect to={isAuth ? '/order' : '/login'} />
      </Switch>
    </Suspense>
  )
}

export default AppRoutes
