import React from 'react'
import { withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { Provider } from 'react-redux'
import { I18nextProvider } from 'react-i18next'
import Cookies from 'js-cookie'

import AppRoutes from './routes'
import Navbar from './layouts/Navbar'
import Sidebar from './layouts/Sidebar'
import Footer from './layouts/Footer'

import store from '../src/state'
import i18n from './translation'

import './App.scss'

const App = () => {
  const isAuth = Cookies.get('mem_epp_rsid')
  const _renderNavbar = isAuth ? <Navbar /> : ''
  const _renderSidebar = <Sidebar />

  return (
    <Provider store={store}>
      <script src='https://cdn.jsdelivr.net/npm/algoliasearch@4.5.1/dist/algoliasearch-lite.umd.js' />
      <script src='https://cdn.jsdelivr.net/npm/algoliasearch@4.5.1/dist/algoliasearch.umd.js' />
      <script src='https://www.gstatic.com/firebasejs/8.3.3/firebase-app.js' />
      <script src='https://www.gstatic.com/firebasejs/8.3.3/firebase-analytics.js' />
      <script src='https://www.gstatic.com/firebasejs/8.3.3/firebase-auth.js' />
      <script src='https://www.gstatic.com/firebasejs/8.3.3/firebase-firestore.js' />
      <I18nextProvider i18n={i18n}>
        <div className='container-scroller'>
          {_renderSidebar}
          <div className={`container-fluid page-body-wrapper`}>
            {_renderNavbar}
            <div className='main-panel'>
              <div className='content-wrapper'>
                <AppRoutes />
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </I18nextProvider>
    </Provider>
  )
}

export default withTranslation()(withRouter(App))
