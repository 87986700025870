import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import Logo from '../assets/images/logo-epispost.png'
import LogoMini from '../assets/images/logo-epispost-mini.png'
import { Button } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import {authSelector} from "../state/auth/reducer";

class Sidebar extends Component {
  state = {}

  componentDidMount() {
    // this.onRouteChanged()
    // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    const body = document.querySelector('body')
    document.querySelectorAll('.sidebar .nav-item').forEach((el) => {
      el.addEventListener('mouseover', function() {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.add('hover-open')
        }
      })
      el.addEventListener('mouseout', function() {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.remove('hover-open')
        }
      })
    })
  }

  toggleMenuState(menuState) {
    if (this.state[menuState]) {
      this.setState({ [menuState]: false })
    } else if (Object.keys(this.state).length === 0) {
      this.setState({ [menuState]: true })
    } else {
      Object.keys(this.state).forEach((i) => {
        this.setState({ [i]: false })
      })
      this.setState({ [menuState]: true })
    }
  }

  // componentDidUpdate(prevProps) {
  //   if (this.props.location !== prevProps.location) {
  //     this.onRouteChanged()
  //   }
  // }

  onRouteChanged() {
    document.querySelector('#sidebar').classList.remove('active')
    Object.keys(this.state).forEach((i) => {
      this.setState({ [i]: false })
    })

    const dropdownPaths = [
      { path: '/apps', state: 'appsMenuOpen' },
      { path: '/basic-ui', state: 'basicUiMenuOpen' },
      { path: '/form-elements', state: 'formElementsMenuOpen' },
      { path: '/tables', state: 'tablesMenuOpen' },
      { path: '/icons', state: 'iconsMenuOpen' },
      { path: '/transfer-money', state: '' },
      { path: '/charts', state: 'chartsMenuOpen' },
      { path: '/user-pages', state: 'userPagesMenuOpen' },
      { path: '/error-pages', state: 'errorPagesMenuOpen' }
    ]

    dropdownPaths.forEach((obj) => {
      if (this.isPathActive(obj.path)) {
        this.setState({ [obj.state]: true })
      }
    })
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path)
  }

  render() {
    return (
      <nav className='sidebar sidebar-offcanvas bg-light' id='sidebar'>
        <div className='sidebar-brand-wrapper d-none d-lg-flex align-items-center justify-content-center fixed-top'>
          <a className='sidebar-brand brand-logo' href='/home'>
            <img src={Logo} alt='logo' style={{ height: 50 }} />
          </a>
          <a className='sidebar-brand brand-logo-mini' href='/home'>
            <img src={LogoMini} alt='logo' />
          </a>
        </div>
        <ul className='nav'>
          <li className='mt-3 nav-item nav-category d-block d-sm-none d-md-none d-lg-none d-xl-none'>
            <Button variant='success' className='btn btn-success ml-3'>
              <Link to='/order/orderDetail/add' variant='h6' className='text-light text-nowrap'>
                + Tạo tờ khai
              </Link>
            </Button>
          </li>
          <li className='nav-item nav-category'>
            <span className='nav-link'>
              <span>Menu</span>
            </span>
          </li>
          {/* <li className={this.isPathActive('/home') ? 'nav-item menu-items active' : 'nav-item menu-items'}>
            <Link className='nav-link' to='/home'>
              <span className='menu-icon'>
                <i className='mdi mdi-home'></i>
              </span>
              <span className='menu-title'>
                <span>Trang chủ</span>
              </span>
            </Link>
          </li> */}

          <li className={this.isPathActive('/order') ? 'nav-item menu-items active' : 'nav-item menu-items'}>
            <Link className='nav-link' to='/order'>
              <span className='menu-icon'>
                <i className='mdi mdi-cart-outline'></i>
              </span>
              <span className='menu-title'>
                <span>Tờ khai hải quan</span>
              </span>
            </Link>
          </li>

          <li className={this.isPathActive('/searchOrder') ? 'nav-item menu-items active' : 'nav-item menu-items'}>
            <Link className='nav-link' to='/searchOrder'>
              <span className='menu-icon'>
                <i className='mdi mdi-magnify'></i>
              </span>
              <span className='menu-title'>
                <span>Tra cứu đơn hàng</span>
              </span>
            </Link>
          </li>

          <li className={this.isPathActive('/transfer-money') ? 'nav-item menu-items active' : 'nav-item menu-items'}>
            <Link className='nav-link' to='/transfer-money'>
              <span className='menu-icon text-primary'>$</span>
              <span className='menu-title'>
                <span>Chuyển tiền</span>
              </span>
            </Link>
          </li>

          <li className={this.isPathActive('/chat') ? 'nav-item menu-items active' : 'nav-item menu-items'}>
            <Link className={`nav-link ${this.props.syncingFirebase ? 'disabled-link' : ''}`} to='/chat'>
              <span className='menu-icon text-primary'>
                <i className='mdi mdi-email' />
              </span>
              <span className='menu-title'>
                <span>Trò chuyện</span>
              </span>
            </Link>
          </li>

          <li
            className={this.isPathActive('/internationalCall') ? 'nav-item menu-items active' : 'nav-item menu-items'}
          >
            <Link className='nav-link' to='/internationalCall'>
              <span className='menu-icon text-primary'>
                <i className='mdi mdi-phone' />
              </span>
              <span className='menu-title'>
                <span>Cuộc gọi quốc tế</span>
              </span>
            </Link>
          </li>

          <li className={this.isPathActive('/verifyAccount') ? 'nav-item menu-items active' : 'nav-item menu-items'}>
            <Link className='nav-link' to='/verifyAccount'>
              <span className='menu-icon text-primary'>
                <i className='mdi mdi-account-check' />
              </span>
              <span className='menu-title'>
                <span>Xác thực tài khoản</span>
              </span>
            </Link>
          </li>

          {/* <li className={this.isPathActive('/profile') ? 'nav-item menu-items active' : 'nav-item menu-items'}>
            <Link className='nav-link' to='/profile'>
              <span className='menu-icon'>
                <i className='mdi mdi-account'></i>
              </span>
              <span className='menu-title'>
                <span>Thông tin cá nhân</span>
              </span>
            </Link>
          </li> */}
        </ul>
      </nav>
    )
  }
}

const WrapperSidebar = (props) => {
  const { syncingFirebase } = useSelector(authSelector)
  return <Sidebar {...props} syncingFirebase={syncingFirebase}/>
}

export default withRouter(WrapperSidebar)
