import get from 'lodash.get'
import { takeEvery, put, all, call, fork } from 'redux-saga/effects'
import {
  login,
  loginSuccess,
  loginError,
  logout,
  logoutSuccess,
  forgotPassword,
  resetPassword,
  registerUser,
  verifyOTP,
  sendOTP,
  syncingFirebaseSuccess
} from './reducer'
import {
  loginRedux,
  loginWithToken,
  logout as logoutQuery,
  resetPasswordRedux,
  forgotPasswordRedux,
  registerUserRedux,
  sendRegisterOTPRedux,
  verifyRegisterOTPRedux
} from './query'
import fetchData from '../../utils'
import Cookies from 'js-cookie'
import { COOKIE_TOKEN_NAME } from '../../utils/constant'
import { firebaseApp } from '../../connections/firebase'
import { userIndex } from '../../connections/algolia'

const db = firebaseApp.firestore()

function* syncFirebaseInfo({ firebaseToken, user, user_phone }) {
  let firebaseUid
  if (firebaseToken) {
    try {
      const firebaseUser = yield firebaseApp.auth().signInWithCustomToken(firebaseToken)
      firebaseUid = firebaseUser.user.uid
    } catch (_) {}
  }
  const userRef = db.collection('user')
  yield userRef.doc(firebaseUid).set({
    uid: firebaseUid,
    displayName: user.full_name,
    photoURL: user.image,
    email: user.email,
    phone: user_phone.phone,
    nation_phone_code: user_phone.nation_phone_code,
  })
  // Write to the algolia index
  yield userIndex.saveObject({
    objectID: firebaseUid,
    uid: firebaseUid,
    displayName: user.full_name,
    image: user.image,
    email: user.email,
    phone: user_phone.phone,
    nation_phone_code: user_phone.nation_phone_code
  });
  yield put(loginSuccess({ ...user, firebaseUid }))
  yield put(syncingFirebaseSuccess())
  return firebaseUid
}

function* watchLogin({ payload: { payload, callback } }) {
  const res = yield call(fetchData, 'login', loginRedux, payload, false)
  const errors = get(res, 'errors', null)
  if (errors) {
    const errorMsg = get(errors, '[0].message', null)
    yield put(loginError(errorMsg))
    return callback && callback({ error: errorMsg })
  }
  const data = get(res, 'data.login', null)
  if (data) {
    yield fork(syncFirebaseInfo, { firebaseToken: data.firebase_token, user: data.user, user_phone: data.user.user_phone })
    yield put(loginSuccess(get(data, 'user', {})))
    return callback && callback(data)
  }
}

function* checkIsAuth() {
  const token = Cookies.get(COOKIE_TOKEN_NAME)

  if (!token) return

  const res = yield call(fetchData, 'loginWithToken', loginWithToken, {
    token
  })
  const errors = get(res, 'errors', null)
  if (errors) {
    Cookies.remove(COOKIE_TOKEN_NAME)
    return (window.location.pathname = '/login')
  }
  const data = get(res, 'data.loginWithToken', null)
  if (data) {
    yield fork(syncFirebaseInfo, { firebaseToken: data.firebase_token, user: data.user, user_phone: data.user.user_phone })
    yield put(loginSuccess(get(data, 'user', {})))
  }
}

function* watchLogout({ payload: { payload, callback } }) {
  const res = yield call(fetchData, 'logout', logoutQuery, payload)
  const errors = get(res, 'errors', null)
  if (errors) {
    get(errors, '[0].message', null)
    return callback && callback(false)
  }
  const data = get(res, 'data.logout', null)
  if (data) {
    try {
      yield firebaseApp.auth().signOut()
    } catch (_) {}
    yield put(logoutSuccess())
    return callback && callback(true)
  }
}

function* watchForgotPassword({ payload: { payload, callback } }) {
  const res = yield call(fetchData, 'forgotPassword', forgotPasswordRedux, payload, false)

  if (callback) {
    callback(res)
  }
}

function* watchResetPassword({ payload: { payload, callback } }) {
  const res = yield call(fetchData, 'resetPassword', resetPasswordRedux, payload, false)

  if (callback) {
    callback(res)
  }
}

function* watchRegisterUser({ payload: { payload, callback } }) {
  const res = yield call(fetchData, 'registerUser', registerUserRedux, payload, false)

  if (callback) {
    callback(res)
  }
}

function* watchSendOTP({ payload: { payload, callback } }) {
  const res = yield call(fetchData, 'sendRegisterOTP', sendRegisterOTPRedux, payload)

  if (callback) {
    callback(res)
  }
}

function* watchVerifyOTP({ payload: { payload, callback } }) {
  const res = yield call(fetchData, 'verifyRegisterOTP', verifyRegisterOTPRedux, payload)

  if (callback) {
    callback(res)
  }
}

export const rootSagas = function* rootSagas() {
  yield all([
    fork(checkIsAuth),
    takeEvery(login.type, watchLogin),
    takeEvery(logout.type, watchLogout),
    takeEvery(forgotPassword.type, watchForgotPassword),
    takeEvery(resetPassword.type, watchResetPassword),
    takeEvery(registerUser.type, watchRegisterUser),
    takeEvery(sendOTP.type, watchSendOTP),
    takeEvery(verifyOTP.type, watchVerifyOTP)
  ])
}
